// $primary: #20c997;

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100..700&family=Roboto+Slab:wght@100..900&display=swap');

body {
	font-family: 'Roboto Slab';
}

input,
select {
	font-family: 'Roboto Mono';
	font-weight: 450 !important;
}

input:focus {
	outline: 0;
}

.px-10 {
	padding-left: 20rem;
	padding-right: 20rem;
}

.fs-7 {
	font-size: 0.7rem;
}

.fs-8 {
	font-size: 0.8rem;
}

.fs-9 {
	font-size: 0.9rem;
}

.capitalize {
	text-transform: capitalize;
}

.text-alt-success {
	color: #23cc7d;
}

.bg-gray {
	background-color: $gray-900;
}

.fw-900 {
	font-weight: 900;
}

.mono {
	font-family: 'Roboto Mono';
}

.floating-el {
	position: absolute;
	// top: 100%;
	z-index: $zindex-dropdown;
	//   display: none; // none by default, but block on "open" of the menu
	min-width: $dropdown-min-width;
	padding: $dropdown-padding-y $dropdown-padding-x;
	margin: 0; // Override default margin of ul
	@include font-size($dropdown-font-size);
	color: $dropdown-color;
	text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
	list-style: none;
	background-color: $dropdown-bg;
	background-clip: padding-box;
	border: $dropdown-border-width solid $dropdown-border-color;
	@include border-radius($dropdown-border-radius);
	@include box-shadow($dropdown-box-shadow);
	cursor: pointer;
}

.title-case::first-letter {
	text-transform: uppercase;
}

.container-home {
	display: grid;
	grid-template-columns: repeat(auto-fill, 240px);
	justify-content: center;
	gap: 15px;
}

.container-istoric {
	display: grid;
	grid-template-columns: repeat(auto-fill, 337px);
	justify-content: space-between;
}

.container-taskuri {
	display: grid;
	grid-template-columns: 1fr;
	max-width: 1000px;
}

.container-searchbox {
	display: grid;
	grid-template-columns: 35px 1fr auto;
	justify-content: space-between;
}

.icon-elimina {
	position: absolute;
	height: 15px;
	float: right;
	z-index: 100;
	cursor: pointer;
	bottom: 10px;
	right: 15px;
}

.form-control:focus,
.form-select:focus {
	border-color: #ffca2c;
	box-shadow: 0 0 8px rgba(#ffca2c, 0.9);
}

// .form-control-plaintext:focus {
// 	border: 0;
// }
